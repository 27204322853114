import React from "react"
import { withTranslation } from "react-i18next"
import '../../dist/fonts/icofont/icofont.min.css'
import './drinks.scss'
import QuestionBubble from "../QuestionBubble"
import SmartForm from "../SmartForm"
import Heading from "../Heading"
import rehypeReact from 'rehype-react'
import MarkdownImage from '../MarkdownImage'
import _ from "lodash"
import Meta from "../Meta"


class Drinks extends React.Component {

    state = {
        reservationDate: ""
    }

    componentDidMount() {

        const { locale } = this.props
        const $ = require('jquery')
        require("air-datepicker")
        require("air-datepicker/dist/js/i18n/datepicker." + locale)

        const dpInit = {
            language: locale,
            minDate: new Date(),
            position: "top left",
            timepicker: true,
            minuteStep: 15,
            onSelect: (formattedDate, newDate) => {
                this.setState({reservationDate: formattedDate})

            }
        }

        if (dpInit.language === 'hu') {
            dpInit.dateFormat = "yyyy.mm.dd.";
            dpInit.timeFormat = "hh:mm"
        }

        $(".reservation-date").datepicker(dpInit);
    }
    
    render() {

        const { t, content } = this.props
        const { subtitle, questionTitle, questionEmail, questionPhone, questionDescription, contentDescription, description, keywords } = this.props.data
        let MAX_GUEST_NUMBER = 30

        const renderAst = new rehypeReact({
            createElement: React.createElement,
            components: { 'markdown-image': MarkdownImage },
        }).Compiler

        return (
            <div className="drinks content pt-100">
                <Meta
                    keywords={keywords}
                    description={description}
                />
                <div className="container">
                    <div className="Drinks-row row pb-50">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                            <Heading title={subtitle} />
                            <span className="description">{contentDescription}</span>
                        </div>
                        <div class="col-xs-12 blog-post-item ml-auto mr-auto">
                            <div class="blog-wrapper blog-details">
                                <div class="blog-content">
                                    { renderAst(content) }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row question-area pb-50">
                        <div class="col-lg-8 col-md-12">
                            <h3>{t('would_you_like_to_reserve_a_table?')}</h3>                    
                            <SmartForm 
                                successTitle={t('your_reservation_request_has_been_sent_successfully')}
                                successMessage={t('our_colleagues_will_contact_you_as_soon_as_possible')} 
                                formClassName="etalon-form" 
                                formName="etalon-reservation" 
                                mailSubject="Etalon asztalfoglalás érkezett"
                                stateProps={["name", "email", "phone", "reservation-date", "guest-number", "message"]}
                                stateValues={{["reservation-date"]: () => this.state.reservationDate}}
                                defaultMessageFields={["name", "phone", "reservation-date", "email"]}>
                                <div class="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="single-input form-group">
                                            <input name="name" placeholder={t('my_name')} type="text" required />
                                        </div>  
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="single-input form-group">
                                            <input name="email" type="email" placeholder={t('my_email')} required />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="single-input form-group">
                                            <input name="phone" type="phone" placeholder={t('my_phone')} required />
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-sm-12">
                                        <div className="single-input select date form-group">
                                            <input className="reservation-date" autocomplete="off" name="reservation-date" type="text" placeholder={t('the_reservation_date')} required />
                                        </div>  
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="select form-group">
                                            <select name="guest-number" required >
                                                <option value="">{t('number_of_guests')}</option>
                                                { _.range(1, MAX_GUEST_NUMBER).map(value => <option key={value} value={value}>{value}</option>) }
                                            </select>
                                        </div>  
                                    </div>
                                    <div className="col-md-12">
                                        <div className="single-input form-group">
                                            <textarea className="form-control" name="message" placeholder={t('my_message')} />
                                        </div>  
                                    </div>
                                    <div className="col-sm-12">
                                        <button className="sent-btn form-group col-lg-auto" type="submit">
                                            {t('send_reservation')}
                                        </button>
                                    </div>
                                </div>
                            </SmartForm>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <QuestionBubble 
                                title={questionTitle} 
                                email={questionEmail} 
                                phone={questionPhone} 
                                description={questionDescription}>
                                <img className="homoky_logo" src="/images/logo/hh.svg" alt="Homoky hotels logo" />
                            </QuestionBubble>
                        </div>
                    </div>
                </div>
            </div>         
        )
    }
}
export default withTranslation()(Drinks)